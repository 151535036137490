/*jslint nomen: true, devel: true */
/*global jQuery, alert, AF */

AF.Datapal = (function ($) {
  'use strict';
  return {
    init: function () {
      AF.info('AF.Datapal.init');
      this.addPlaceHolders();
      this.countCharacters();
      this.securityQsReveal();
      this.showHideText();
    //  this.mobileDetect();

      $('#poe_question_dropdown').bind('change', $.proxy(this, "handleSecurityQuestion"));
  },

  addPlaceHolders: function(){
    $('#MemorableDay').attr('placeholder','GG');
    $('#MemorableMonth').attr('placeholder','AA');
    $('#MemorableYear').attr('placeholder','YYYY');
  },

  countCharacters: function(){
     $('#count').on('keyup mouseup', function (e) {
        var text_max = $(this).attr('data-max');
        var text_length = $('#count').val().length;
        var text_remaining = text_max - text_length;

        $("#count").keyup(function(){
          if($(this).val().length > text_max){
            $(this).val($(this).val().substr(0, text_max));
          }
          var remaining = text_max -  $(this).val().length;

          if(remaining <= 0){
            $('#countfeedback').html('Bu alan i&#231;in izin verilen maksimum karakter say&#305;s&#305; 1500&#8217;d&#252;r.');
          }else if(remaining <= 10){
            $("#count").parent().addClass('has-warning');
          }else{
            $("#count").parent().removeClass('has-warning');
          }
        });
        if (text_remaining === 0) {
          return;
        }else{
          $('#countfeedback').html(text_max + ' Zeichen m&ouml;glich');
          $('#countfeedback').html(text_remaining + ' Zeichen m&ouml;glich');
        }
      });
  },

  securityQsReveal : function(){
    $('.pwd-reveal').bind('click',function(){
      var currentInput = $(this).closest('.row').find('input:not([type=hidden])')
      if (currentInput.attr('type') === 'password' ){
        currentInput.attr('type','text');
      }else if (currentInput.attr('type') === 'text'){
        currentInput.attr('type','password');
      }
   });

  },
  showHideText: function(){
    $('.pwd-reveal').mouseover( function(){
      $(this).find('.pwd-reveal-info').show()
    });
    $('.pwd-reveal').mouseout( function(){
      $(this).find('.pwd-reveal-info').hide()
    });
  },

/*
  mobileDetect: function(){
    var md = new MobileDetect(window.navigator.userAgent);
    var devicetype = md.mobile();
    if (devicetype == null) {
        if ((navigator.appVersion.indexOf("Mac")!=-1) && (navigator.userAgent.indexOf("Safari")!=-1)){
            $(".desktop-btn").addClass("hide");
            $( ".mobile-btn" ).removeClass("hide");
        }
        else{
            $(".desktop-btn" ).removeClass("hide");
            $(".mobile-btn").addClass("hide");
        }
    }
    else{
        $(".desktop-btn").addClass("hide");
        $( ".mobile-btn" ).removeClass("hide");
    }
  },
*/

  handleSecurityQuestion: function(event) {
    var sc = $("#poe_question_dropdown :selected").val()
    $('#securityAnswerGroup').addClass("hidden");
    if(sc !== "") {
      $('#securityAnswerGroup input').addClass('hidden');
      $('#securityAnswerGroup').removeClass("hidden");
      switch (sc) {
        case "201":
        $('#BirthPlace').removeClass('hidden');
        break;

        case "202":
        $('#FavouriteActorLastName').removeClass('hidden');
        break;204

        case "203":
        $('#NickName').removeClass('hidden');
        break;

        case "204":
        $('#FavouriteBook').removeClass('hidden');
        break;

        case "41":
        $('#FirstCarMake').removeClass('hidden');
        break;

        case "42":
        $('#FirstPetName').removeClass('hidden');
        break;

        case "43":
        $('#CustomPoeAnswer').removeClass('hidden');
        break;
      }
    } else {
      $('#securityAnswerGroup').addClass("hidden");
    }
  }

  };
} (jQuery));
