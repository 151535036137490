AF.Rules = AF.Rules || {};

AF.Rules.Webenable = {
  groups: {
    dateOfBirth: "BirthDay BirthMonth BirthYear"
 },
  rules: {
    EnableWebAccess_MemberNumber: { required: true},
    EnableWebAccess_FirstName: { required: true, germannames:true},
    EnableWebAccess_LastName: { required: true, germannames:true},
    EnableWebAccess_SMSPhoneNumber: { required: false},
    EnableWebAccess_AcademicTitle: { required: false},
    BirthDay: { required: true,number:true},
    BirthMonth: { required: true,number:true},
    BirthYear: { required: true,number:true},
    EnableWebAccess_Email: { required: true, germanemail:true},
    EnableWebAccess_EmailVerify: { required: true, equalTo:'#EMail'},
    EnableWebAccess_Password: { required: true, germanpassword:true},
    EnableWebAccess_PasswordVerify: { required: true, equalTo:'#Password'},
    AgreeTerms: { required: true},
    EnableWebAccess_AgreeTerms: { required: true},
    Question_Dropdown: {securityquestion: true},
    BirthPlace: {selectanswer: true},
    FavouriteActorLastName: {selectanswer: true},
    NickName: {selectanswer: true},
    FavouriteBook: {selectanswer: true}
  },
  messages: {
    EnableWebAccess_MemberNumber: { required: 'Bitte geben Sie Ihre Kundennummer ein.'},
    EnableWebAccess_FirstName: { required: 'Bitte geben Sie Ihren Vornamen ein.', germannames:'Bitte geben Sie Ihren Vornamen ein.'},
    EnableWebAccess_LastName: { required: 'Bitte geben Sie Ihren Nachnamen ein.', germannames:'Bitte geben Sie Ihren Nachnamen ein.'},
    EnableWebAccess_SMSPhoneNumber: { required: 'Bitte geben Sie Ihre Handynummer ohne Leerzeichen, Bindestrich oder Sonderzeichen ein.'},
    BirthDay: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    BirthMonth: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    BirthYear: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    EnableWebAccess_Email:{required:'Bitte geben Sie eine gültige eMail Adresse ein.', germanemail:'Bitte geben Sie eine gültige eMail Adresse ein.'},
    EnableWebAccess_EmailVerify:{required:'Bitte bestätigen Sie Ihre eMail Adresse.', equalTo:'Bitte bestätigen Sie Ihre eMail Adresse.'},
    EnableWebAccess_Password:{required:'Bitte geben Sie ein gültiges Passwort ein.', germanpassword:'Bitte geben Sie ein gültiges Passwort ein.'},
    EnableWebAccess_PasswordVerify:{required:'Bitte best&auml;tigen Sie Ihr Passwort.', equalTo:'Bitte best&auml;tigen Sie Ihr Passwort.'},
    AgreeTerms: { required: 'Bitte best&auml;tigen Sie die AGB und die Datenschutzbestimmungen.'},
    EnableWebAccess_AgreeTerms: { required: 'Bitte best&auml;tigen Sie die AGB und die Datenschutzbestimmungen.'},
    Question_Dropdown: { securityquestion: 'Bitte w&auml;hlen Sie Ihre Sicherheitsfrage aus.'}

  }
};