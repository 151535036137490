AF.Rules = AF.Rules || {};

AF.Rules.CreateSecondary = {
  groups: {
    dateOfBirth: "CreateSecondary_BirthDay CreateSecondary_BirthMonth CreateSecondary_BirthYear"
 },
  rules: {
    CreateSecondary_Prefix:{ required: true},
    CreateSecondary_FirstName: { required: true},
    CreateSecondary_LastName: { required: true},
   CreateSecondary_BirthDay: { required: true,number:true},
    CreateSecondary_BirthMonth: { required: true,number:true},
    CreateSecondary_BirthYear: { required: true,number:true},
    CreateSecondary_Email: { required: true,germanemail:true}
  },
  messages: {
    CreateSecondary_Prefix:{required: 'Bitte w&auml;hlen Sie eine Anrede.'},
    CreateSecondary_FirstName: { required: 'Bitte geben Sie Ihren Vornamen ein.'},
    CreateSecondary_LastName: { required: 'Bitte geben Sie Ihren Nachnamen ein.'},
    CreateSecondary_BirthDay: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    CreateSecondary_BirthMonth: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    CreateSecondary_BirthYear: { required: 'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.', number:'Bitte geben Sie ein g&uuml;ltiges Geburtsdatum ein.'},
    CreateSecondary_Email:{required:'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.',germanemail:'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.'}
  }
};



