AF.Rules = AF.Rules || {};

AF.Rules.Signin = {
  rules: {
    signin_username: { required: true,germanemail:true},
    signin_password: { required: true}
  },
  messages: {
    signin_username: { required: 'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.', germanemail:'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.'},
    signin_password: { required: 'Bitte geben Sie ein g&uuml;ltiges Passwort ein.'}
  }
};
