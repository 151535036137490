/*jslint nomen: true, devel: true */
/*global jQuery, alert */

var AF = (function ($) {
  'use strict';
  var _privateVariable = 0;
  var defaultOptions = {
    debug: true,
    console: {
      log:      { color: '#000000', title: 'LOG' },
      warn:     { color: '#f0ad4e', title: 'WARN' },
      success:  { color: '#3c763d', title: 'GOOD' },
      info:     { color: '#31708f', title: 'INFO' },
      error:    { color: '#a94442', title: 'ERROR' }
    }
  };
  var options;

  return {

    /*
     * Function runs automatically on page load.
     * @return
     */
    init: function (opt) {
      console.group('AF.init v1.0');
      var _opt = opt || {};
      options = $.extend(defaultOptions, opt);
      AF.initCookieConsent();
      AF.bootstrap();
      for(var ns in AF) {
        if(AF.hasOwnProperty(ns)) {
          var fstChar = ns.charAt(0);
          if(fstChar.toUpperCase() === fstChar) {
            if(typeof AF[ns].init === 'function') {
              AF[ns].init();
            }
          }
        }
      }
      console.groupEnd();
    },

    /*
     * Function to initilize the cookie consent message on the top.
     * @return Node
     */
    initCookieConsent: function () {
      AF.info('AF.initCookieConsent');
      return $('#cookieConsent').cookieconsent({
        expires: 30,
        cookieName: '_cookieaccepted',
        button: '#cookieConsentButton'
      });
    },

    /*
     * Function to initilize bootstrap plugins
     * - Popovers [rel=popover]
     * - Tooltip [rel=tooltip]
     * - Modals
     */
    bootstrap: function() {
      AF.info('AF.bootstrap');
      $('[rel=popover]').popover({});
      $('[rel=tooltip]').tooltip({});
    },

    showLogs: function(messages, type) {
      if(options.debug) {
        if(messages.length <= 1) {
          console.log('%c[' + options.console[type].title +']\t: ' + messages[0], 'font-size: 12px; line-height: 2; font-weight: bold; color: ' + options.console[type].color);
        } else {
          console.group('%c [' + options.console[type].title +']', 'font-size: 12px; line-height: 2; font-weight: bold; color:' + options.console[type].color);
          for(var arg in messages) {
            if(typeof messages[arg] === 'string'){
              console.log('%c' + messages[arg], 'font-size: 12px; line-height: 2; font-weight: bold; color: ' + options.console[type].color);
            } else {
              console.log(messages[arg]);
            }

          }
          console.groupEnd();
        }
      }
    },

    log: function() {
      AF.showLogs.call(this, arguments, 'log');
    },
    warn: function() {
      AF.showLogs.call(this, arguments, 'warn');
    },
    success: function() {
      AF.showLogs.call(this, arguments, 'success');
    },
    info: function() {
      AF.showLogs.call(this, arguments, 'info');
    },
    error: function() {
      AF.showLogs.call(this, arguments, 'error');
    }
  };

}(jQuery));
