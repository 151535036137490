/*jslint nomen: true, devel: true */
/*global jQuery, alert, AF */

AF.Forms = (function ($) {
  'use strict';
  return {

    init: function () {
      AF.info('AF.Forms.init');
      AF.Forms.addMethods();
      AF.Forms.Config = {
        securityAnswersElements: {
          'Q_201': '#BirthPlace',
          'Q_202': '#FavouriteActorLastName',
          'Q_203': '#NickName',
          'Q_204': '#FavouriteBook'
        }
      };

      AF.Forms.setupValidations();
      $('.formSubmitButton').bind('click', $.proxy(this, 'handleUpdateProfileSubmit'));
      AF.Forms.smsAlertSelected();
      //$('.form__refresh-button').bind('click', $.proxy(this, 'reloadCaptcha'));
    },

    initDebug: function () {

    },

    handleUpdateProfileSubmit: function(event) {
      AF.info('handleUpdateProfileSubmit');
      event.preventDefault();

      if($(event.currentTarget).data('action-type') === 'marketing') {
        $('form[name=UpdateProfileFormControl]').attr('action', '/secure/ChangeMarketingPreferences.aspx');
      } else if($(event.currentTarget).data('action-type') === 'security') {
        $('form[name=UpdateProfileFormControl]').attr('action', '/secure/UpdatePoe.aspx');
      } else {
        $('form[name=UpdateProfileFormControl]').attr('action', '/secure/UpdateProfile.aspx');
      }
      $('form[name=UpdateProfileFormControl]').submit();

    },
    addMethods: function () {
      AF.info('AF.Forms.addMethods');
      $.validator.addMethod("specialcharacters", function (value, element) {
        return this.optional(element) || !/[^a-zA-Z0-9\s]+$/.test(value);
      }, "It's not valid");

      $.validator.addMethod("dateofbirth", function (value, element) {
        var BirthDay = $('#BirthDay').val();
        var BirthMonth = $('#BirthMonth').val();
        var BirthYear = $('#BirthYear').val();
        return !(BirthDay == "" || BirthMonth == "" || BirthYear == "")
      }, "Bitte geben Sie ein gültiges Geburtsdatum ein.");

      $.validator.addMethod("cardexpirydate", function (value, element) {
        var ExpirationMonth = $('#ExpirationMonth').val();
        var ExpirationYear = $('#ExpirationYear').val();
        return !(ExpirationMonth == "" || ExpirationYear == "")
      }, "Expiry date required.");

      $.validator.addMethod("securityquestion", function (value, element) {
        return !(value =='');
      }, "Bitte wählen Sie eine Sicherheitsfrage aus.");

      $.validator.addMethod("reasontocontact", function (value, element) {
        return !(value =='');
      }, "Bitte wählen Sie einen Grund aus.");

      $.validator.addMethod("contactquestion", function (value, element) {
        return !(value =='');
      }, "Bitte wählen Sie ein Thema aus.");

      $.validator.addMethod("alertPref", function (value, element) {
        var chk1 =  document.getElementById("ReceiveAlerts").checked;
        var chk2 =  document.getElementById("SMSAlerts").checked;

        if ( chk1 || chk2 == true){
          $('#SMSAlerts-error').css('display','none');
          $('#ReceiveAlerts-error').css('display','none');
          return !(value =='');
        }

      }, "Bitte wählen Sie, wie sie Ihre Alarmmeldung erhalten möchten. ");

      jQuery.validator.addMethod("germanpassword", function(value, element) {
        return this.optional(element) || /^((?=.*?[A-ZÜÄÖÉ])(?=.*?[a-züäöéß])|(?=.*?[A-ZÜÄÖÉ])(?=.*?\d)|(?=.*?[A-ZÜÄÖÉ])(?=.*[\W_])|(?=.*?[a-züäöéß])(?=.*?\d)|(?=.*?[a-züäöéß])(?=.*[\W_])|(?=.*?\d)(?=.*[\W_])).{8,15}$/.test(value);
      }, "Bitte geben Sie ein gültiges Passwort ein.");

      jQuery.validator.addMethod("germanemail", function(value, element) {
        return this.optional(element) || /^(?!\.)(?!^.*?([@]).*\1.*$)(?!^.*?([>\(\)<\[\] \;\:]).*$)(?!.*\.{2})[a-zA-Z0-9ÄÖÉÜäöéüß!#$%&'*+-=?^_`{|}~\/.]*[a-zA-Z0-9!#$%&'*+-=?^_`{|}~\/]*[^.]+@((([a-zA-Z0-9ÄÖÉÜäöéüß-])+\.)+([a-zA-Z0-9ÄÖÉÜäöéüß-]{2,5}))+$/.test(value);
      }, "Bitte geben Sie eine gültige eMail Adresse ein.");

      jQuery.validator.addMethod("germansmsnumber", function(value, element) {
        return this.optional(element) || /^[+]?[0-9]*$/.test(value);
      }, "Bitte geben Sie Ihre Handynummer ohne Leerzeichen, Bindestrich oder Sonderzeichen ein.");

      jQuery.validator.addMethod("germannames", function(value, element) {
        return this.optional(element) || /^[A-Za-zäöüÄÖÜß]+([\-\.\s,'A-Za-zäöüÉéÄÖÜß]*[A-Za-zäöüÉéÄÖÜß\.\-\,'])?$/.test(value);
      }, "Bitte geben Sie Ihren Vornamen ein");


      $.validator.addMethod("selectanswer", function (value, element) {
        var dropdown_val = $('#poe_question_dropdown').val();
        if(AF.Forms.Config.securityAnswersElements['Q_' + dropdown_val] === ('#' + $(element).attr('id'))) {
          if($(element).val() === '') {
            return false;
          }
        }
        return true;
      }, "Bitte geben Sie hier Ihre Antwort ein.");

    },

    errorPlacementNone: function (error, element) {
      return true;
    },

    highlight: function (element) {
      $(element).closest('.form-group').addClass('has-error');
    },

    unhighlight: function (element) {
      $(element).closest('.form-group').removeClass('has-error').addClass('has-success');
    },

    setupValidations: function() {
      // profile form validation
    /* AF.Forms.initFormValidation('form.enroll-step1',{
        rules: AF.Rules.Step1.rules,
        messages: AF.Rules.Step1.messages
      });

      AF.Forms.initFormValidation('form.enroll-step1-devk',{
        rules: AF.Rules.Step1devk.rules,
        messages: AF.Rules.Step1devk.messages
      });


      AF.Forms.initFormValidation('form.enroll-step2',{
        rules: AF.Rules.Step2.rules,
        messages: AF.Rules.Step2.messages,
        groups: {
          alertPreference:"ReceiveAlerts SMSAlerts",
          dateofbirth: "BirthDay BirthMonth BirthYear"
        }
      });
*/


      AF.Forms.initFormValidation('form.signin',{
        rules: AF.Rules.Signin.rules,
        messages: AF.Rules.Signin.messages
      });

       AF.Forms.initFormValidation('form.webenable',{
        groups: AF.Rules.Webenable.groups,
        rules: AF.Rules.Webenable.rules,
        messages: AF.Rules.Webenable.messages
      });

      AF.Forms.initFormValidation('form.forgotpassword-step1',{
        rules: AF.Rules.ForgotPasswordStep1.rules,
        messages: AF.Rules.ForgotPasswordStep1.messages
      });

      AF.Forms.initFormValidation('form.forgotpassword-step2',{
        rules: AF.Rules.ForgotPasswordStep2.rules,
        messages: AF.Rules.ForgotPasswordStep2.messages
      });

      AF.Forms.initFormValidation('form.forgotpassword-step3',{
        rules: AF.Rules.ForgotPasswordStep3.rules,
        messages: AF.Rules.ForgotPasswordStep3.messages
      });

      AF.Forms.initFormValidation('form.passwordreset',{
        rules: AF.Rules.ResetPassword.rules,
        messages: AF.Rules.ResetPassword.messages
      });

      AF.Forms.initFormValidation('form.createsecondary',{
        groups: AF.Rules.CreateSecondary.groups,
        rules: AF.Rules.CreateSecondary.rules,
        messages: AF.Rules.CreateSecondary.messages
      });

      AF.Forms.initFormValidation('form.contactus',{
        rules: AF.Rules.Contactus.rules,
        messages: AF.Rules.Contactus.messages
      });

      //BWBank,Telekom
      /*
      AF.Forms.initFormValidation('form.enroll-step1-bwbank',{
        groups: AF.Rules.Step1bwbank.groups,
        rules: AF.Rules.Step1bwbank.rules,
        messages: AF.Rules.Step1bwbank.messages
      });

      AF.Forms.initFormValidation('form.enroll-step2-bwbank',{
        rules: AF.Rules.Step2bwbank.rules,
        messages: AF.Rules.Step2bwbank.messages
      });

      AF.Forms.initFormValidation('form.enroll-step1-avira',{
        rules: AF.Rules.Step1avira.rules,
        messages: AF.Rules.Step1avira.messages
      });*/

    },

    initFormValidation: function(elForm, option) {
      $(elForm).validate({
        groups: option.groups,
        rules: option.rules,
        messages: option.messages,
        highlight: this.highlight,
        unhighlight: this.unhighlight,
        errorElement: 'span',
        errorClass: 'popover fade right in',
        errorPlacement: this.errorPlacement,
        invalidHandler: function(form, validator) {
          /*$(".form-group.has-success span.popover[style='display: block;']").each(function(){
            $(this).parent('.form-group').addClass("has-error");
          });*/
        },
      });
      return this;
    },
    errorPlacement: function (error, element) {
      if (element.parent('.input-group-wrap').length) {
        error.insertAfter(element.parent('.input-group-wrap').find('.lastelement'));
      } else {
        error.insertAfter(element);
      }
    },
     /*reloadCaptcha: function(t) {
        var i = $(t.currentTarget);
        i.addClass("form__refresh-button--rotate"), $.ajax({
            url: "/secure/ReloadCaptcha.aspx?FontFamily=Century Schoolbook&TextDarkColor=" + encodeURIComponent("#9e8238") + "&TextLightColor=" + encodeURIComponent("#9e8238"),
            type: "get",
            dataType: "text",
            success: function(t) {
                $("#hipImage").attr("src", t), i.removeClass("form__refresh-button--rotate")
            },
            error: function() {}
        }), t.preventDefault()
    },*/
    smsAlertSelected: function(){
      var numberLabel = $(".smshandynumber").parent(".form-group").find("label");
      $("#SMSAlerts").change(function(){
        //console.log($("#SMSAlerts:checked").length);
        if ($("#SMSAlerts:checked").length) {
        //  AF.Rules.Step2bwbank.rules.SMSNumber.required = true;
        //  AF.Rules.Step2.rules.SMSNumber.required = true;
          AF.Rules.Webenable.rules.EnableWebAccess_SMSPhoneNumber.required = true;
         $('.number-mandatory').css('display','block');
        $('.number-optional').css('display','none');
        } else {
        //  AF.Rules.Step2bwbank.rules.SMSNumber.required = false;
        //  AF.Rules.Step2.rules.SMSNumber.required = false;
          AF.Rules.Webenable.rules.EnableWebAccess_SMSPhoneNumber.required = false;
           $('.number-mandatory').css('display','none');
          $('.number-optional').css('display','block');
        }
        $('form.webenable').removeData('validator');
        AF.Forms.setupValidations();
      });
    }
  };
} (jQuery));
