AF.Rules = AF.Rules || {};

AF.Rules.Contactus = {
  rules: {
    FirstName: { required: true},
    LastName: { required: true},
    Email: { required: true,germanemail:true},
    reason: {reasontocontact: true},
    question: {contactquestion: true},
    Comments: {required: true}
  },
  messages: {
    FirstName: { required: 'Bitte geben Sie Ihren Vornamen ein.'},
    LastName: { required: 'Bitte geben Sie Ihren Nachnamen ein.'},
    Email:{required:'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.',germanemail:'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.'},
    reason: {reasontocontact: 'Bitte w&auml;hlen Sie einen Grund aus.' },
    question: { contactquestion: 'Bitte w&auml;hlen Sie ein Thema aus.'},
    Comments: { required: 'Bitte geben Sie hier Ihre Nachricht ein.'}
  }
};