AF.Rules = AF.Rules || {};

AF.Rules.ForgotPasswordStep1 = {
  rules: {
    forgot_email_hidden: { required: true}
  },
  messages: {
    forgot_email_hidden: { required: 'Bitte geben Sie eine g&uuml;ltige eMail Adresse ein.'}
  }
};

AF.Rules.ForgotPasswordStep2 = {
  rules: {
    MemorableDay: { required: true,number:true},
    MemorableMonth: { required: true,number:true},
    MemorableYear: { required: true,number:true},
    BirthPlace: { required: true},
    ElementarySchool: { required: true}
  },
  messages: {
    MemorableDay: { required: 'Dieses Feld muss ausgef&#252;llt werden', number:'Bitte geben Sie ein gültiges Datum im Format TT MM JJJJ ein'},
    MemorableMonth: { required: 'Dieses Feld muss ausgef&#252;llt werden', number:'Bitte geben Sie ein gültiges Datum im Format TT MM JJJJ ein'},
    MemorableYear: { required: 'Dieses Feld muss ausgef&#252;llt werden', number:'Bitte geben Sie ein gültiges Datum im Format TT MM JJJJ ein'},
    BirthPlace: { required: 'Bitte geben Sie Ihren Geburtsort ein'},
    ElementarySchool: { required: 'Bitte geben Sie den M&#228;dchennamen Ihrer Mutter ein'}
  }
};

AF.Rules.ForgotPasswordStep3 = {
  rules: {
    Password: { required: true,germanpassword:true},
    PasswordVerify: { required: true}
  },
  messages: {
    Password: { required: 'Bitte geben Sie ein g&uuml;ltiges Passwort ein.',germanpassword:'Bitte geben Sie ein g&uuml;ltiges Passwort ein.'},
    PasswordVerify: { required: 'Bitte best&auml;tigen Sie Ihr Passwort.'}
  }
};

AF.Rules.ResetPassword = {
  rules: {
    Password: { required: true,germanpassword:true},
    PasswordVerify: { required: true}
  },
  messages: {
    Password: { required: 'Bitte geben Sie ein g&uuml;ltiges Passwort ein.',germanpassword:'Bitte geben Sie ein g&uuml;ltiges Passwort ein.'},
    PasswordVerify: { required: 'Bitte bestätigen Sie Ihr neues Passwort'}
  }
};
