$(document).ready(function(){

  /* Form popovers */

  if(isMobile()) {
    $('.pop-right').popover({
      placement: 'top'
    });

    $('.pop-right').on('shown.bs.popover', function (evt) {
      $('.popover ').animate({
        left: $(evt.currentTarget).parents('.form-group').offset().left
      });
    });
    $(window).off('.affix')
    $('.affix').removeData('affix').removeClass('affix affix-top affix-bottom');
  } else {
    $('.pop-right').popover();
  }

    if($('.updateprofile').length >=1) {
      $('html, body').animate({scrollTop: 5}, 'slow');
    }

  /* Timed popover  */
  /* window.setTimeout(showLoggedOut, (60*1000) *20);*/

  $('#loggedOutModal .close, #loggedOutModal .btn').click(function(){
      var sourcehash = $("[name='defaultsourceid']").val();
     window.location.replace("/secure/SignOut.aspx?source="+sourcehash);
    setCookie('ASP.NET_SessionId', '', -1);
  });

  $('select').addClass("form-control");

  // session time out when browser in active
  var isSessionAlive = true;
  var sessionCheck = setInterval(function(){
    if(!isSessionAlive) {
      showLoggedOut();
      isSessionAlive = true;
    }
  }, 100);

  var resetSession = setInterval(function() {
    isSessionAlive = false;
  }, (60*1000)*20);

  $(document).on('keydown mousemove mousedown', function(){
    isSessionAlive = true;
  });

  function closeModal(){
    $('#pcdownload').modal('hide');
    $('#datamonitor').modal('hide');
    $('#esafe').modal('hide');
    $('#ie9Modal').modal('hide');
    $('#mobdownload').modal('hide');
  }

  function initialiseModal(){
    $('#pcdownload').modal({show: false});
    $('#datamonitor').modal({show: false});
    $('#esafe').modal({show: false});
    $('#ie9Modal').modal({show: false});
    $('#mobdownload').modal({show: false});
  }

  initialiseModal();

  function showLoggedOut(){
    closeModal();
    $('#loggedOutModal').modal('show');
    setCookie('modalCookie', 'No', -1);
  }


  $('.pop-right').click(function(){
    var thisParent = $(this).parent();
    var thisId = $(this).attr('id');
    $(this).data("container", 'test');
    thisParent.addClass(thisId);
    if($(thisParent).hasClass('has-error')){
      $(this).popover('hide');
    };
  });

  $(function(){
    $('#accordion').on('shown.bs.collapse', function (e) {
      var offset = $('.panel.panel-default > .panel-collapse.in').offset();
      if(offset)$('html,body').animate({scrollTop: offset.top - 60}, 300);
    });
    $('.panel-title a').click(function(){
      if (!$(this).hasClass("collapsed")) {
        $('html,body').delay(350).animate({scrollTop: 0}, 300);
      }
    });
  });

   //Custom validate the email field and amend the browser default error message
  $('#signinEmail').on('invalid', function(e){
    e.target.setCustomValidity("");
    if (!e.target.validity.valid) { e.target.setCustomValidity("Bitte geben Sie eine gültige eMail Adresse ein."); }
  });

  // auto tab dob/memorable date fields
  $('#BirthDay,#BirthMonth,#MemorableDay,#MemorableMonth').on('keyup', function (e){
    var length = $(this).val().length;
    if ( length == 2 ){
      if ($(this).next('input').attr('type') == 'hidden'){
        $(this).next('input').next('input').focus();
      }else{
        $(this).next('input').focus();
      }
    }
  });

  // masking security details.
  $(function(){
    $('#MemorableDay,#MemorableMonth,#MemorableYear,#BirthCity,#ElementarySchool').removeAttr('type').prop('type', 'password');
    $('#ElementarySchool').attr('maxlength','30');
   });

  $("[data-toggle='modal']").click(function(){
    var element = $(this).attr('data-target');
    setCookie('modalCookie', 'Yes');
    setCookie('modalOpen', element);
  });
  var cookieValue = getCookie('modalCookie');
  if(cookieValue!="No"){
    var elementOpen = cookieValue = getCookie('modalOpen');
    $(elementOpen).modal('show');
  }
  $('.close').click(function(){
    setCookie('modalCookie', 'No', -1);
  });

  /* update Profile disabled submit */
  var form = $('.updateprofile form');
  form.change(function() {
    form.find('button').removeAttr('disabled');
  });

  // if the card type AMX - adding validation, cvv number should be 4digits long
  $( "#PayMethod" ).on('change', function() {
    if (this.value == 'AMX') {
      jQuery.validator.addMethod("amxsecuritycode", function(value, element) {
        return this.optional(element) || (value.length == 4)
      },"Please enter 4 digit security code")
    }else{
      jQuery.validator.addMethod("amxsecuritycode", function(value, element) {
        return this.optional(element) || (value.length == 3)
      },"Please enter 3 digit security code")

    }
  });

// update profile - passpattern
  jQuery.validator.addMethod("passnew", function(value, element) {
    return true;
  }, "L&#252;tfen ge&#231;erli bir &#351;ifre giriniz");

  // Feb month validation - date should be in between 1 to 28
  $("#MemorableMonth").on('keyup mouseup',function(){
    if ( $('#MemorableMonth').val() == '2'){
      jQuery.validator.addMethod("datepattern", function(value, element) {
        return this.optional(element) || !( $('#MemorableDay').val() > 28)
      }, "L&#252;tfen 1 ile 28 aras&#305;nda ge&#231;erli bir g&#252;n giriniz");
    }else{
      jQuery.validator.addMethod("datepattern", function(value, element) {
        return this.optional(element) || !( $('#MemorableDay').val() > 31)
      }, "1 ile 31 aral&#305;&#287;&#305;nda ge&#231;erli bir tarih giriniz");
    }
  });


  /* Validate Error wrap */
  $('span.validateError').wrap('<div class="alert alert-danger alert-dismissable"></div>');

  /* Tooltip */
  $('.infotip').click(function(){
    $(this).parent().parent().find('.tooltip-text-area').slideToggle();
    var offset = $(this).offset();
    if(offset)$('html,body').animate({scrollTop: offset.top - 60}, 300);
  });
  $('.tooltip-text-area .close').click(function(){
    $(this).parent().fadeOut();
    $('html,body').delay(350).animate({scrollTop: 0}, 300);
  });

    $('.pop-right').bind('click', function(){
        var target = $(document).find('.popover a.close');
        target.click(function(){
            $(this).parent().parent().removeClass('in');
            return false;
        });
    });
});


/* JS Cookie functions */
function getCookie(w){
  var cName = "";
  var pCOOKIES = new Array();
  pCOOKIES = document.cookie.split('; ');
  for(var bb = 0; bb < pCOOKIES.length; bb++){
    var NmeVal  = new Array();
    NmeVal  = pCOOKIES[bb].split('=');
    if(NmeVal[0] == w){
      cName = unescape(NmeVal[1]);
    }
  }
  return cName;
}

function printCookies(w){
  cStr = "";
  pCOOKIES = new Array();
  pCOOKIES = document.cookie.split('; ');
  for(bb = 0; bb < pCOOKIES.length; bb++){
    NmeVal  = new Array();
    NmeVal  = pCOOKIES[bb].split('=');
    if(NmeVal[0]){
      cStr += NmeVal[0] + '=' + unescape(NmeVal[1]) + '; ';
    }
  }
  return cStr;
}

function setCookie(name, value, expires, path, domain, secure){
  document.cookie = name + "=" + escape(value) + "; ";
  if(expires){
    expires = setExpiration(expires);
    document.cookie += "expires=" + expires + "; ";
  }
  if(path){
    document.cookie += "path=" + path + "; ";
  }
  if(domain){
    document.cookie += "domain=" + domain + "; ";
  }
  if(secure){
    document.cookie += "secure; ";
  }
}

function setExpiration(cookieLife){
    var today = new Date();
    var expr = new Date(today.getTime() + cookieLife * 24 * 60 * 60 * 1000);
    return  expr.toGMTString();
}

function copyPastePolicy () {
    // to disable copy-pasting on or from a field just apply html attribute: data-copy="false" !
    $(function () {
        var controls = $(".form-group").find('[data-copy="false"]');
        controls.bind("paste", function () {
            return false;
        });
        controls.bind("drop", function () {
            return false;
        });
        controls.bind("cut", function () {
            return false;
        });
        controls.bind("copy", function () {
            return false;
        });
    });
}
copyPastePolicy();

// incresing sticky footer height on forgot password pages
$(function(){
  if ( $( "#page" ).hasClass( "forgotPassword")){
    $('.footer').css('height','165px');
  }
});

function isIE () {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

function isMobile() {
 if( navigator.userAgent.match(/Android/i)
 || navigator.userAgent.match(/webOS/i)
 || navigator.userAgent.match(/iPhone/i)
 || navigator.userAgent.match(/iPad/i)
 || navigator.userAgent.match(/iPod/i)
 || navigator.userAgent.match(/BlackBerry/i)
 || navigator.userAgent.match(/Windows Phone/i)
 ){
    return true;
  }
 else {
    return false;
  }
}

//java -jar c:\\bin\compiler.jar --compilation_level SIMPLE_OPTIMIZATIONS --js_output_file=bundle.min.js bootstrap.js jsValidate.js master.js offcanvas.js

//light box height based on the window size
var resize = function() {
  var windowHeight = $( window ).height(),
  modelHeight = windowHeight - 100;
  // $('#datamonitor .modal.in .modal-dialog, .modal-content, iframe').css('height', modelHeight-85 +'px');
  $('#datamonitor .modal.in .modal-dialog, .modal-content').css('height', modelHeight-85 +'px');
  $('.modal-content').css('height',modelHeight+'px');
  };
$(window).resize(resize);
resize();

